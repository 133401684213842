<template>
  <div
    v-if="client"
    v-scroll="onScroll"
  >
    <v-container
      fluid
      class="pa-0"
    >
      <v-row
        dense
        class="overview-card"
        justify="center"
      >
        <v-col
          v-if="showBilling"
          cols="12"
          sm="5"
        >
          <material-overview-client-overview-card
            :button-name="$t('overview.editBillingAddress')"
            button-link="billing-details"
            data-cy="tb-billing-details"
            icon="mdi-domain"
          >
            <v-alert
              dense
              icon="mdi-domain"
              prominent
            >
              <h1 class="card-company-name">
                {{ client.billingDetails.billing.company }}
              </h1>
              <div
                v-t="'address'"
                class="overline"
              />
              <p>
                {{ client.billingDetails.billing.address }}
                <br>
                {{ client.billingDetails.billing.city }}<br>
                {{ client.billingDetails.billing.state.value }}
              </p>
              <div class="overline">
                <span v-t="'overview.companyId'" />
              </div>
              {{ client.billingDetails.billing.registrationNumber }}<br>
              <div class="overline">
                <span v-t="'overview.vatId'" />
              </div>
              {{ client.billingDetails.billing.vatNumber }}
            </v-alert>
            <v-alert
              dense
              icon="mdi-mail"
              prominent
            >
              <div
                v-t="'email'"
                class="overline"
              />
              <p class="mt-2">
                {{ client.billingDetails.billing.email }}
              </p>
            </v-alert>
          </material-overview-client-overview-card>
        </v-col>
        <v-col
          v-if="showInvoices"
          cols="12"
          :sm="showBilling ? 7 : 12"
          class="mobile-table-helper-container"
        >
          <v-icon
            v-if="showInvoicesTableHelper"
            class="mobile-table-helper"
          >
            mdi-arrow-left-bold
          </v-icon>
          <material-overview-client-overview-card
            v-intersect.once="{ handler: onInvoicesIntersect, options: { threshold: 1 }}"
            icon="mdi-clipboard-outline"
            :title="$t('overview.invoicesQuotations')"
            value="1"
            small-value="unpaid invoice"
            :button-name="$t('overview.allInvoicesQuotations')"
            button-link="invoices-and-quotations"
            table
          >
            <v-simple-table
              ref="invoicesTableContainer"
              class="overview-card-table"
            >
              <tbody ref="invoicesTableBody">
                <tr
                  v-for="invoice in invoices"
                  :key="invoice.id"
                >
                  <td>{{ invoice.number }}</td>
                  <td>{{ invoice.issuedDate | formatDate }}</td>
                  <td>{{ invoice.status }}</td>
                  <td>
                    <v-icon
                      class="mr-n1"
                      @click="onClickDownload(invoice.id)"
                    >
                      mdi-file-download
                    </v-icon>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </material-overview-client-overview-card>
        </v-col>
      </v-row>
      <template v-if="showContracts">
        <material-overview-client-contract-card
          v-for="contract in contracts"
          :key="contract.id"
          :contract="contract"
          class="mb-2 mt-2"
        />
      </template>
      <v-row
        dense
        class="overview-card"
        justify="center"
      >
        <v-col
          v-if="hasAdminPermission"
          md="4"
          sm="6"
          cols="12"
        >
          <material-overview-client-overview-card
            icon="mdi-credit-card"
            :title="$t('overview.paymentDetails')"
            value="PayPal"
            :button-name="$t('overview.paymentsOverview')"
            button-link="payment-details"
          >
            <p v-text="$tc('overview.recurringPayments', paymentsCount)" />
            <template v-if="paymentsCount">
              <v-divider />
              <div
                v-t="'overview.nextPayment'"
                class="mt-2"
              />
              <div>
                <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
                <span v-t="'payment.paymentMethod'" />:
                {{ nextPayment.methodName }}
              </div>
              <div v-if="nextPayment.nextPayment">
                <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
                <span v-t="'payment.nextPayment'" />:
                {{ nextPaymentDate | formatDate }}
              </div>
            </template>
          </material-overview-client-overview-card>
        </v-col>
        <v-col
          v-if="hasAdminPermission"
          md="4"
          sm="6"
          cols="12"
        >
          <material-overview-client-overview-card
            icon="mdi-account-multiple"
            :title="$t('overview.teamMembers')"
            value="4"
            small-value="members"
            :button-name="$t('overview.manageTeamMembers')"
            button-link="team-members"
          >
            <p v-t="'overview.manageUsers'" />
            <p>
              {{ $tc("overview.currentMembers", members ? members.length : 0) }}
            </p>
          </material-overview-client-overview-card>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <material-overview-client-overview-card
            icon="mdi-lifebuoy"
            :title="$t('overview.needHelp')"
            value="2"
            small-value="already created tickets"
            :button-name="$t('overview.visitSupportCenter')"
            button-link="support-center"
          >
            <p v-t="'overview.needHelpInfo'" />
            <template #button>
              <v-col cols="auto">
                <material-support-new-ticket-modal-window
                  data-cy="support-ticket"
                  button-name="Create ticket"
                />
              </v-col>
            </template>
          </material-overview-client-overview-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import {
  DELIMITER,
  DOWNLOAD_ACCESS_LEVEL,
  PURCHASE_ORDERS_ACCESS_LEVEL,
  BILLING_ACCESS_LEVEL,
  ADMIN_ACCESS_LEVEL,
  PERMISSIONS_NAME
} from '../../../../constants/app'

export default {
  data () {
    return {
      delimiter: DELIMITER,
      pageScrolled: false,
      invoicesTableVisibleInViewPort: false,
      invoicesTableOverflowing: false
    }
  },
  computed: {
    ...mapState('client', {
      clientClients: 'clients'
    }),
    ...mapState('contract', {
      contractClients: 'clients'
    }),
    ...mapState('invoicesAndQuotations', {
      invoiceClients: 'clients'
    }),
    ...mapState('member', {
      memberClients: 'clients'
    }),
    ...mapGetters('recurringPayments', ['paymentsCountGetter', 'nextPaymentGetter']),
    clientId () {
      return this.$route.params.clientId
    },
    nextPayment () {
      return this.nextPaymentGetter(this.clientId)
    },
    nextPaymentDate () {
      return new Date(this.nextPayment.nextPayment).toISOString()
    },
    paymentsCount () {
      return this.paymentsCountGetter(this.clientId)
    },
    client () {
      return this.clientClients[this.clientId]
    },
    contracts () {
      return this.contractClients[this.clientId]
    },
    invoices () {
      if (
        Object.prototype.hasOwnProperty.call(this.invoiceClients, this.clientId) &&
        this.invoiceClients[this.clientId].invoices
      ) {
        return this.invoiceClients[this.clientId].invoices.slice(0, 5)
      }

      return []
    },
    members () {
      return Object.prototype.hasOwnProperty.call(this.memberClients, this.clientId)
        ? this.memberClients[this.clientId].members
        : []
    },
    clientPermissions () {
      return JSON.parse(localStorage.getItem(PERMISSIONS_NAME))
    },
    hasAdminPermission () {
      return this.clientPermissions.includes(ADMIN_ACCESS_LEVEL)
    },
    hasBillingPermission () {
      return this.clientPermissions.includes(BILLING_ACCESS_LEVEL)
    },
    hasPurchasePermission () {
      return this.clientPermissions.includes(PURCHASE_ORDERS_ACCESS_LEVEL)
    },
    hasDownloadPermission () {
      return this.clientPermissions.includes(DOWNLOAD_ACCESS_LEVEL)
    },
    showContracts () {
      return this.hasDownloadPermission || this.hasPurchasePermission || this.hasAdminPermission
    },
    showBilling () {
      return (
        (this.hasBillingPermission || this.hasAdminPermission) &&
        this.client.billingDetails &&
        this.client.billingDetails.billing
      )
    },
    showInvoices () {
      return this.hasBillingPermission || this.hasAdminPermission
    },
    showInvoicesTableHelper () {
      return this.pageScrolled && this.invoicesTableVisibleInViewPort && this.invoicesTableOverflowing
    },
    invoicesTableContainer () {
      return this.$refs.invoicesTableContainer
    }
  },
  methods: {
    ...mapActions([
      'invoicesAndQuotations/downloadInvoicePDF'
    ]),
    onScroll () {
      if (!this.pageScrolled) {
        this.pageScrolled = true
        this.initInvoiceTableOverflow()
      }
    },
    onClickDownload (invoiceId) {
      this['invoicesAndQuotations/downloadInvoicePDF']({
        clientId: this.clientId,
        invoiceId
      })
    },
    onInvoicesIntersect () {
      if (!this.invoicesTableVisibleInViewPort) {
        this.invoicesTableVisibleInViewPort = true
      }
    },
    initInvoiceTableOverflow () {
      const minimalScrollDiff = 2 // px

      if (
        this.$refs.invoicesTableContainer &&
        this.$refs.invoicesTableBody &&
        this.$refs.invoicesTableContainer.$el.clientWidth + minimalScrollDiff <
        this.$refs.invoicesTableBody.clientWidth
      ) {
        this.invoicesTableOverflowing = true
      }
    }
  }
}
</script>

<style lang="scss">
$helperSize: 5rem;

@keyframes slide-left {
  50% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }

  100% {
    left: 5%;
  }
}

.address-icon{
  float:left;
  height: 100%;
}

.mobile-table-helper-container {
  position: relative;
  overflow: hidden;
}

.mobile-table-helper.v-icon {
  position: absolute;
  left: 100%;
  top: calc(50% - #{$helperSize / 2});
  opacity: 0;
  z-index: 1000;
  font-size: $helperSize;
  animation: slide-left 1s;
}

.overview-card-table td {
  white-space: nowrap;

  &:first-of-type {
    padding-left: 16px;
  }

  &:last-of-type {
    padding-right: 16px;
    text-align: right;
  }
}

.card-company-name {
  line-height: 1;
  margin: 16px 0;
}
</style>
